(function () {
  'use strict';

  angular
    .module('neo.home.postponements.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.postponements.edit', {
        url: '^/home/matches/{matchId}/postponement/edit',
        templateUrl: 'home/postponements/edit-postponement/edit-postponement.tpl.html',
        controller: 'EditPostponementCtrl',
        controllerAs: 'vm',
        resolve: {
          facilities: function (Facilities) {
            return Facilities.query().$promise;
          },
          entities: function (Entitats) {
            return Entitats.query().$promise;
          },
          match: function (Matches, $stateParams) {
            return Matches.get({id: $stateParams.matchId}).$promise;
          },
          championship: function (Championship, match) {
            return Championship.get({id: match.championship.id}).$promise;
          },
          recoveryDates: function (PhaseRecoveryMatchDates, championship) {
            return PhaseRecoveryMatchDates.query({id: championship.phaseId}).$promise;
          }
        }
      });
  }
}());
